const algoliaClient = algoliasearch(
  "L7ACFTWS23",
  "53f618ec9d45145ed6c00945e5f62b0c"
);

const searchClient = {
  search(requests) {
    // iff for search
    if (window.innerWidth >= 768) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
          })),
        });
      } else if (requests.every(({ params }) => params.query.length < 2)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
          })),
        });
      }
    } else {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
        })),
      });
    }

    // SEARch condition end

    return algoliaClient.search(requests);
  },
};

const search = instantsearch({
  indexName: "vwGlobalSiteSearch",
  searchClient,
});

//  custom connector
const customHits = instantsearch.connectors.connectHits(
  (renderOptions, isFirstRender) => {
    const { results, widgetParams } = renderOptions;
    const { container } = widgetParams;
    container.innerHTML =
      results && results.query && results.hits.length === 4
        ? `<div>
    <a class="vw-btn" href="/search?q=${results.query}"> More Results</a>
    </div>`
        : `<div></div>`;
  }
);

search.addWidgets([
  instantsearch.widgets.poweredBy({
    container: "#powered-by",
  }),

  instantsearch.widgets.configure({
    clickAnalytics: true,
    page: 1,
    hitsPerPage: 4,
  }),
  instantsearch.widgets.searchBox({
    container: "#searchbox",
    placeholder: "What can we help you find?",
    showReset: false,
    showSubmit: false,
    cssClasses: {
      form: ["searchBox-nav"],
    },
    queryHook(query, search) {
      const link = document.querySelector("#searchNavBtn");
      link.setAttribute("href", `/search?q=${query}`);
      if (window.innerWidth >= 768) {
        search(query);
      } else {
        const link = document.querySelector("#mobSearchBtn");
        link.setAttribute("href", `/search?q=${query}`);
      }
    },
  }),
  instantsearch.widgets.hits({
    container: "#hits",
    templates: {
      item(hit) {
        return `<div class="alg-hit full-width">               
                <h4 class="alg-hit__title">                
                 <a class="alg-hit__link" 
                 href="${hit.url}" 
                 target="_blank"
                 data-insights-object-id="${hit.objectID}"
                 data-insights-position="${hit.__position}"
                 data-insights-query-id="${hit.__queryID}"
                 >                 
                  ${instantsearch.highlight({
                    attribute: "title",
                    hit: hit,
                  })}
                </a>
                </h4>         
                    
                <p class="alg-hit__description">
                ${instantsearch.highlight({
                  attribute: "description",
                  hit: hit,
                })}
                </p>
                <p class="alg-hit__full-link">
                <a href="${hit.url}" target="_blank">${hit.url}</a>
                </p>

              </div>`;
      },
      empty: `   {{#query}}
          <h4 class="full-width alg-hit__empty">No results for  <span>{{query}} </span></h4>
        {{/query}}`,
    },
  }),
  customHits({
    container: document.querySelector("#hits-2"),
  }),
]);

search.start();

// enter-event

const enterTgt = document.querySelector(".searchBox-nav .ais-SearchBox-input");

enterTgt.addEventListener("keypress", function(event) {
  // Number 13 is the "Enter" key on the keyboard
  if (event.keyCode == 13) {
    // Cancel the default action, if needed
    // event.preventDefault();
    // Trigger the button element with a click
    const buttonTgt = document.getElementById("searchNavBtn");
    window.location.href = buttonTgt.href;
  }
});
